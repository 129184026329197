<template>
  <div>
    <h2 class="text-2xl font-bold mb-5">{{ tab | sentenceCase }} cards</h2>
    <div class="grid grid-cols-2 mb-6">
      <div class="col-span-1 flex items-center">
        <tabs :tabs="tabs" v-model="tab" />
      </div>
    </div>

    <component :is="tab" />
  </div>
</template>

<script>
  export default {
    components: {
      personal: require('./Personal').default,
      corporate: require('./Corporate').default,
    },
    data() {
      return {
        searchQuery: '',
        tabs: [
          {name: 'personal', title: 'Personal'},
          {name: 'corporate', title: 'Corporate'},
        ],
        tab: 'personal'
      }
    },
  }
</script>
