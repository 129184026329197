<template>
  <div>
    <div class="flex justify-end">
      <div class="w-full sm:w-1/2">
        <input
          type="search"
          name="search"
          class="border border-solid border-blue-200 rounded placeholder-blue-200 outline-none pl-10 pr-2 py-2 text-14 w-full"
          placeholder="Search for user's name, company's name etc."
          v-model="searchQuery"
          @keyup.enter="$refs.table.loadAjaxData"
        />
        <ion-icon
          name="search-outline"
          class="text-blue-200 absolute z-10 text-lg ml-3 left-0"
          style="top: 50%; transform: translateY(-50%);"
        ></ion-icon>
      </div>
    </div>
    <div class="border border-solid border-blue-200">
      <datatable
        :index="true"
        :reverse-index="true"
        :url="`${this.$baseurl}/admin/corporate/cards`"
        :ajax="true"
        :exportable="true"
        :onClick="goToUser"
        :fillable="true"
        :ajaxPagination="true"
        :data="cards.data"
        :columns="cards.columns"
        :query="searchQuery"
        ref="table"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchQuery: "",
      cards: this.$options.resource([], {
        columns: [
          {
            name: "user",
            th: "User",
            render: (card) =>
              `${card.company_account?.user?.name} ${card.company_account?.user?.last_name}`,
          },
          {
            name: "company",
            th: "Company",
            render: (card) => card.company_account.user?.company?.name || "---",
          },
          {
            name: "card_type",
            th: "Type",
            render: (card) =>
              this.$options.filters.sentenceCase(card.company_account.type),
          },
          {
            name: "available_credit",
            th: "Available Credit",
            render: (card) => {
              if (card.company_account.type == "credit") {
                return `<span class="text-blue-500">₦ ${this.$options.filters.currency(
                  card.company_account.available_credit
                )}</span>`;
              }
              return '<span class="text-gray-500">₦ 0.00</span>';
            },
          },
          {
            name: "credit_limit",
            th: "Credit Limit",
            render: (card) => {
              if (card.company_account.type == "credit") {
                return `<span class="text-blue-500">₦ ${this.$options.filters.currency(
                  card.company_account.available_credit
                )}</span>`;
              }
              return '<span class="text-gray-500">₦ 0.00</span>';
            },
          },
          {
            name: "debit_balance",
            th: "Debit Balance",
            render: (card) => {
              if (card.company_account.type == "debit") {
                return `<span class="text-blue-500">₦ ${this.$options.filters.currency(
                  card.company_account.balance
                )}</span>`;
              }
              return '<span class="text-gray-500">₦ 0.00</span>';
            },
          },
          {
            name: "status",
            th: "Status",
            render: (card) => {
              if (
                card.status == "active" &&
                card.company_account.status == "active"
              ) {
                return '<span class="badge badge-green-soft-outline">Active</span>';
              }

              if (card.pin_status) {
                return '<span class="badge badge-blue-soft-outline">Pin Requested</span>';
              }
              if (!card.pin_status && card.status == "active") {
                return '<span class="badge badge-orange-soft-outline">Pin Not Requested</span>';
              }

              return '<span class="badge badge-orange-soft-outline">Not Ready</span>';
            },
          },
        ],
      }),
    };
  },
  mounted() {
    this.$options.listenForClick.apply(this);
  },
  methods: {
    click(request) {
      this.request = this.request === request ? null : request;
      this.$refs.table.renderData();
    },
    goToUser({ company_account }) {
      const { user } = company_account

      return this.$router.push({
        name: "staff-view",
        params: {
          staffId: user?.id,
        },
      });
    },
    async getCards() {
      this.cards.loading = true;
      await this.sendRequest("admin.cards.corporate.all", {
        success: (response) => {
          this.cards.data = response.data.cards;
          if (this.request) {
            this.request = this.cards.data.find(
              (request) => request.id === this.request.id
            );
          }
        },
      });
      this.cards.loading = false;
    },
  },
};
</script>
