<template>
	<div>

		<h1 class="text-3xl font-bold mb-10">Cards</h1>

		<div class="grid grid-cols-2 mb-8">
			<div class="col-span-1">
				<h2 class="text-2xl font-bold">All Departments</h2>
			</div>
			<div class="col-span-1">
				<div class="relative">
					<input
						type="search"
						name="search"
						class="border border-solid border-blue-200 rounded placeholder-blue-200 outline-none pl-10 pr-2 py-2 text-xs w-full"
						placeholder="Search for department, card number, card name, etc."
						v-model="groups.query">
					<ion-icon name="search-outline" class="text-blue-200 absolute z-10 text-lg ml-3 left-0" style="top: 50%; transform: translateY(-50%);"></ion-icon>
				</div>
			</div>
		</div>

		<template v-if="groups.loading">
			<sm-loader class="mb-10" />
		</template>
		<template v-else>

			<div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4 mb-20">
				<div class="col-span-1">
					<div
						class="bg-blue-000 px-6 py-4 flex items-center justify-between cursor-pointer rounded text-13"
						:class="{'text-gray-500 border-2 border-blue-200 font-bold': selectedGroup, 'text-blue-500 border-2 border-blue-500 font-bold': !selectedGroup}"
						@click="selectGroup(null)"
					>
						<div class="">All</div>
						<div class="">{{ totalCards }}</div>
					</div>
				</div>
				<template v-for="(group, n) in filteredGroups">
					<div class="col-span-1" :key="n">
						<div
							class="bg-blue-000 px-6 py-4 flex items-center justify-between cursor-pointer rounded text-13"
							:class="{'text-gray-500 border-2 border-blue-200 font-bold': selectedGroup !== group, 'text-blue-500 border-2 border-blue-500 font-bold': selectedGroup == group}"
							@click="selectGroup(group)"
						>
							<div class="">{{ group.name }}</div>
							<div class="">{{ getCardCount(group) }}</div>
						</div>
					</div>
				</template>
			</div>

		</template>

		<div class="flex items-end mb-8">
			<h2 class="text-2xl font-bold line-height" style="line-height: .8">
				{{ selectedGroup ? selectedGroup.name : 'All' }} Cards
			</h2>
			<router-link :to="{ name: 'cards-new' }" class="text-blue-800 opacity-75 text-sm ml-3" style="line-height: 1">
				+ Add a Card
			</router-link>
		</div>

		<template v-if="cards.loading">
			<sm-loader class="mb-10" />
		</template>
		<template v-else>

			<div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4 mb-20">

				<template v-if="filteredCards.length">
					<template v-for="(card, n) in filteredCards">
						<div class="col-span-1" :key="n">
							<div class="cp-card">
								<div class="cursor-pointer" @click.prevent="viewCard(card)">
									<credit-card-2
										:class="{'opacity-75': card.data.status === 'inactive'}"
										:card-style="card.style"
										:type="card.data.type"
										:loading="card.loading"
										:name="card.data.user.name"
									/>
								</div>

								<div class="cp-card-content pointer-events-none" v-if="!card.loading">
									<div class="w-full flex flex-row items-center">
										<div class="dropdown ml-auto pointer-events-auto">
											<button class="cp-card-action" dropdown>
												<span class="dot"></span>
												<span class="dot"></span>
												<span class="dot"></span>
											</button>
											<ul class="dropdown-menu whitespace-no-wrap">
												<a href="#!" class="dropdown-menu-item text-blue-500" @click.prevent="openTopUpModal(card)">Top Up Card</a>
												<a href="#!" class="dropdown-menu-item text-red-500">Delete Card</a>
												<a href="#!" class="dropdown-menu-item">Deactivate Card</a>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</template>
				</template>
				<template v-else>
					<div class="col-span-1 sm:col-span-2">
						<div class="alert alert-gray-soft">
							<span class="alert-icon">!</span>
							<span>No Cards Available</span>
						</div>
					</div>
				</template>

			</div>

		</template>

		<modal className="w-full md:w-1/2 lg:w-8/10 xl:w-5/10" ref="detailModal">
			<h1 class="font-bold text-2xl mb-10">
				Card Details
			</h1>

			<template v-if="selectedCard">
				<div class="mb-10">
					<div class="grid grid-cols-1 md:grid-cols-2 border-b border-blue-200">
						<div class="col-span-1 py-4">
							<div class="opacity-75 text-sm font-medium mb-1">Department Name</div>
							<div class="text-gray-500 text-sm flex flex-row items-center">
								<ion-icon name="briefcase-outline" class="text-lg mr-2"></ion-icon>
								{{ getGroup(selectedCard).name }}
							</div>
						</div>
						<div class="col-span-1 py-4 pl-4 -ml-4 border-t md:border-t-0 md:border-l border-blue-200">
							<div class="opacity-75 text-sm font-medium mb-1">Assigned To</div>
							<router-link :to="{ name: 'staff-view', params: { staffId: selectedCard.data.user_id } }" class="text-gray-500 text-sm flex flex-row items-center">
								<ion-icon name="person-outline" class="text-lg mr-2"></ion-icon>
								{{ selectedCard.data.user.name }}
							</router-link>
						</div>
					</div>
					
					<div class="grid grid-cols-1 md:grid-cols-2 border-b border-blue-200">
						<div class="col-span-1 py-4">
							<div class="opacity-75 text-sm font-medium mb-1">Card Type</div>
							<div class="text-gray-500 text-sm flex flex-row items-center">
								<ion-icon name="card-outline" class="text-lg mr-2"></ion-icon>
								{{ selectedCard.data.type | sentenceCase }}
							</div>
						</div>
						<div class="col-span-1 py-4 pl-4 -ml-4 border-t md:border-t-0 md:border-l border-blue-200">
							<div class="opacity-75 text-sm font-medium mb-1">Creation Date</div>
							<div class="text-gray-500 text-sm flex flex-row items-center">
								<ion-icon name="calendar-outline" class="text-lg mr-2"></ion-icon>
								{{ selectedCard.data.created_at | dateFormat('D, dd M, Y') }}
							</div>
						</div>
					</div>

					<div class="grid grid-cols-1 md:grid-cols-2 border-b border-blue-200" v-if="selectedCard.data.type === 'debit'">
						<div class="col-span-1 py-4">
							<div class="opacity-75 text-sm font-medium mb-1">Card Status</div>
							<div class="text-gray-500 text-sm flex flex-row items-center">
								<ion-icon name="briefcase-outline" class="text-lg mr-2"></ion-icon>
								<span class="text-blue-500">Active</span>
							</div>
						</div>
						<div class="col-span-1 py-4 pl-4 -ml-4 border-t md:border-t-0 md:border-l border-blue-200">
							<div class="opacity-75 text-sm font-medium mb-1">Debit Balance</div>
							<div class="text-gray-500 text-sm flex flex-row items-center">
								<ion-icon name="business-outline" class="text-lg mr-2"></ion-icon>
								<span class="mr-3">{{ selectedCard.data.balance | currency }}</span>
								<a href="#!" class="text-sm text-blue-500" @click.prevent="openTopUpModal(selectedCard)">Top Up</a>
							</div>
						</div>
					</div>

					<div class="grid grid-cols-1 md:grid-cols-2 border-b border-blue-200" v-else-if="selectedCard.data.type === 'credit'">
						<div class="col-span-1 py-4">
							<div class="opacity-75 text-sm font-medium mb-1">Credit Limit</div>
							<div class="text-gray-500 text-sm flex flex-row items-center">
								<ion-icon name="lock-closed-outline" class="text-lg mr-2"></ion-icon>
								<span class="mr-3">{{ selectedCard.data.limit | currency }}</span>
								<a href="#!" class="text-sm text-blue-500" @click.prevent="openIncreaseModal(selectedCard)">Increase Limit</a>
							</div>
						</div>
						<div class="col-span-1 py-4 pl-4 -ml-4 border-t md:border-t-0 md:border-l border-blue-200">
							<div class="opacity-75 text-sm font-medium mb-1">Available Credit</div>
							<div class="text-gray-500 text-sm flex flex-row items-center">
								<ion-icon name="cash-outline" class="text-lg mr-2"></ion-icon>
								<span class="mr-3">{{ selectedCard.data.available_credit | currency }}</span>
								<a href="#!" class="text-sm text-blue-500" @click.prevent="openTopUpModal(selectedCard)">Top Up</a>
							</div>
						</div>
					</div>
				</div>
				<div>
					<button type="type" class="btn btn-red-outline mr-3" :disabled="selectedCard.loading || 1">
						<span v-if="selectedCard.loading">Please Wait</span>
						<span v-else-if="deleteForm.loading">Deleting</span>
						<span v-else>Delete Card</span>
					</button>
					<button type="type" class="btn btn-orange-soft" :disabled="selectedCard.loading">
						<span v-if="selectedCard.loading">Please Wait</span>
						<span v-else-if="deleteForm.loading">Deleting</span>
						<span v-else>Deactivate Card</span>
					</button>
				</div>
			</template>
			<template v-else>
				<div class="text-sm mb-6">
					Please select a card to view.
				</div>
				<div class="text-center">
					<button type="button" class="btn btn-blue btn-md" @click.prevent="$refs.topUpModal.close">
						Okay
					</button>
				</div>
			</template>
		</modal>

		<modal className="w-full md:w-2/5 xl:w-3/10 text-center" ref="topUpModal">
			<h1 class="font-bold text-2xl mb-10">
				Top Up Card
			</h1>

			<template v-if="selectedCard">
				<form @submit.prevent="topUp">
					<template v-if="getFormError(topUpForm)">
						<div class="alert alert-red-soft mb-10">
							<span class="alert-icon">!</span>
							<span>{{ getFormError(topUpForm) }}</span>
						</div>
					</template>

					<div class="mb-10">
						<label class="text-sm font-bold text-blue-400">Enter Amount</label>
						<input-money
							type="text"
							class="text-2xl text-gray-400 font-black w-full text-center py-4 focus:outline-none focus:text-gray-900"
							placeholder="0.00"
							:precision="1"
							v-model="topUpForm.data.amount.value"
						/>
						<div class="text-sm text-red-500" v-if="getFirstError('amount', topUpForm)">
							{{ getFirstError('amount', topUpForm) }}
						</div>
					</div>

					<div class="text-center">
						<button
							type="submit"
							class="btn btn-blue btn-md"
							:disabled="topUpForm.loading"
						>
							<span v-if="topUpForm.loading">Loading</span>
							<span v-else>Top Up</span>
						</button>
					</div>
				</form>
			</template>
			<template v-else>
				<div class="text-sm mb-6">
					Please select a card to top up.
				</div>
				<div class="text-center">
					<button type="button" class="btn btn-blue btn-md" @click.prevent="$refs.topUpModal.close">
						Okay
					</button>
				</div>
			</template>
		</modal>

		<modal className="w-full md:w-2/5 xl:w-3/10 text-center" ref="increaseModal">
			<h1 class="font-bold text-2xl mb-10">
				Increase Credit Limit
			</h1>

			<template v-if="selectedCard">
				<form @submit.prevent="increaseLimit">
					<template v-if="getFormError(increaseForm)">
						<div class="alert alert-red-soft mb-10">
							<span class="alert-icon">!</span>
							<span>{{ getFormError(increaseForm) }}</span>
						</div>
					</template>

					<div class="mb-10">
						<label class="text-sm font-bold text-blue-400">Enter New Credit Limit</label>
						<input-money
							type="text"
							class="text-2xl text-gray-400 font-black w-full text-center py-4 focus:outline-none focus:text-gray-900"
							placeholder="0.00"
							:precision="1"
							v-model="increaseForm.data.credit_limit.value"
						/>
						<div class="text-sm text-red-500" v-if="getFirstError('credit_limit', increaseForm)">
							{{ getFirstError('credit_limit', increaseForm) }}
						</div>
					</div>

					<div class="text-center">
						<button
							type="submit"
							class="btn btn-blue btn-md"
							:disabled="increaseForm.loading"
						>
							<span v-if="increaseForm.loading">Loading</span>
							<span v-else>Top Up</span>
						</button>
					</div>
				</form>
			</template>
			<template v-else>
				<div class="text-sm mb-6">
					Please select a card to top up.
				</div>
				<div class="text-center">
					<button type="button" class="btn btn-blue btn-md" @click.prevent="$refs.increaseModal.close">
						Okay
					</button>
				</div>
			</template>
		</modal>

		<modal className="w-full md:w-2/5 xl:w-3/10 text-center pt-24" ref="successModal">
			<img :src="checkmark" class="mx-auto mb-8" height="90" alt="Check Mark">

			<div class="text-lg font-bold mb-4">
				Success!
			</div>
			<div class="text-sm mb-10">
				Your card has been topped up successfully.
			</div>

			<button type="button" class="btn btn-blue" @click.prevent="$refs.successModal.close">
				Okay
			</button>
		</modal>

	</div>
</template>
<script>
	export default {
		data() {
			return {
				selectedGroup: null,
				selectedCard: null,
				usedQuery: false,
				checkmark: require('@/assets/checkmark-base.svg'),
				groups: this.$options.resource([], { query: '' }),
				cards: this.$options.resource([]),
				topUpForm: this.$options.basicForm(['amount']),
				increaseForm: this.$options.basicForm(['credit_limit']),
				deleteForm: this.$options.basicForm(),
			}
		},
		computed: {
			filteredGroups() {
				return this.groups.data.filter(group => {
					const regex = new RegExp(this.groups.query, 'i')
					return JSON.stringify(group).match(regex);
				});
			},
			filteredCards() {
				return this.cards.data.filter(card => {
					if (this.selectedGroup) {
						return card.group_id == this.selectedGroup.id;
					}else {
						return true;
					}
				}).map((card) => ({
					// ...card,
					...this.$options.resource(card),
					style: card.type == 'credit' ? 'blue' : 'blue-dark',
				}));
			},
			totalCards() {
				return this.cards.data.length;
			},
		},
		beforeMount() {
			this.getGroups();
			this.getCards();
		},
		methods: {
			selectGroup(group) {
				this.selectedGroup = group;
			},
			getCardCount(group) {
				return this.cards.data.filter(card => card.group_id === group.id).length
			},
			getGroup(card) {
				return this.groups.data.find(group => group.id === card.data.group_id);
			},
			openTopUpModal(card) {
				this.selectedCard = card;
				this.$refs.topUpModal.open();
			},
			openIncreaseModal(card) {
				this.selectedCard = card;
				this.$refs.increaseModal.open();
				this.increaseForm.data.credit_limit.value = card.data.limit;
			},
			viewCard(card) {
				this.selectedCard = card;
				this.$refs.detailModal.open();
			},


			async getGroups() {
				this.groups.loading = true;

				await this.sendRequest('corporate.groups.all', {
					success: response => {
						this.groups.data = response.data.groups;

						if (!this.usedQuery && this.$route.query?.groupId) {
							this.selectedGroup = this.groups.data.find(group => group.id == this.$route.query.groupId);
						}
					},
					error: error => {
						this.groups.error = error;
					}
				});

				this.groups.loading = false;
			},
			async getCards() {
				this.cards.loading = true;

				await this.sendRequest('corporate.cards.all', {
					success: response => {
						this.cards.data = response.data.data;

						if (this.selectedCard) {
							const card = this.cards.data.find(card => card.id == this.selectedCard.data.id);
							if (card) {
								this.selectedCard = {
									...this.selectedCard,
									...this.$options.resource(card),
								};
							}else {
								this.selectedCard = null;
							}
						}
					},
					error: error => {
						this.cards.error = error;
					}
				});

				this.cards.loading = false;
			},
			async topUp() {
				if (!this.validateForm(this.topUpForm)) {
					return false;
				}

				const type = this.selectedCard.data.type;

				this.selectedCard.loading = true;
				this.topUpForm.loading = true;

				await this.sendRequest(`corporate.cards.${type}.fund`, {
					data: {
						company_id: this.selectedCard.data.company_id,
						company_account_id: this.selectedCard.data.id,
						amount: this.topUpForm.data.amount.value
					},
					success: () => {
						// this.selectedCard.data = response.data.data;
						this.$refs.topUpModal.close();
						this.$refs.successModal.open();
						this.getCards();
					},
					error: error => {
						this.selectedCard.error = error;
						this.topUpForm.error = error;
					}
				});

				this.selectedCard.loading = false;
				this.topUpForm.loading = false;
			},
			async increaseLimit() {
				if (!this.validateForm(this.increaseForm)) {
					return false;
				}

				this.selectedCard.loading = true;
				this.increaseForm.loading = true;

				await this.sendRequest(`corporate.cards.credit.increase_limit`, {
					data: {
						account_id: this.selectedCard.data.id,
						credit_limit: this.increaseForm.data.credit_limit.value
					},
					success: () => {
						// this.selectedCard.data = response.data.data;
						this.$refs.increaseModal.close();
						this.$refs.successModal.open();
						this.getCards();
					},
					error: error => {
						this.selectedCard.error = error;
						this.increaseForm.error = error;
					}
				});

				this.selectedCard.loading = false;
				this.increaseForm.loading = false;
			}
		}
	}
</script>