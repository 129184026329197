<template>
  <div>
    <div class="flex justify-end">
      <div class="w-full sm:w-1/2">
        <input
          type="search"
          name="search"
          class="border border-solid border-blue-200 rounded placeholder-blue-200 outline-none pl-10 pr-2 py-2 text-14 w-full"
          placeholder="Search for user's name, company's name etc."
          v-model="searchQuery"
          @keyup.enter="$refs.table.loadAjaxData"
        />
        <ion-icon
          name="search-outline"
          class="text-blue-200 absolute z-10 text-lg ml-3 left-0"
          style="top: 50%; transform: translateY(-50%);"
        ></ion-icon>
      </div>
    </div>
    <div class="border border-solid border-blue-200">
      <datatable
        :index="true"
        :reverse-index="true"
        :url="`${this.$baseurl}/admin/personal/cards`"
        :ajax="true"
        :onClick="goToUser"
        :exportable="true"
        :fillable="true"
        :ajaxPagination="true"
        :data="cards.data"
        :columns="cards.columns"
        :query="searchQuery"
        ref="table"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchQuery: "",
      cards: this.$options.resource([], {
        columns: [
          {
            name: "user",
            th: "User",
            // render: card => card.personal_account?.user?.name
            render: (card) => {
              if (!card.personal_account) {
                return "N/A";
              }
              return `${card.personal_account?.user?.name} ${card.personal_account?.user?.last_name}`;
            },
          },
          {
            name: "card_type",
            th: "Type",
            render: (card) => {
              if (card.personal_account?.type === "credit") {
                return "Credit";
              }
              return "Debit";
            },
          },
          {
            name: "available_credit",
            th: "Available Credit",
            render: (card) => {
              if (card.personal_account) {
                return `<span class="text-blue-500">₦ ${this.$options.filters.currency(
                  card.personal_account?.available_credit
                )}</span>`;
              }
              return '<span class="text-gray-500">₦ 0.00</span>';
            },
          },
          {
            name: "credit_limit",
            th: "Credit Limit",
            render: (card) => {
              if (card.personal_account) {
                return `<span class="text-blue-500">₦ ${this.$options.filters.currency(
                  card.personal_account?.credit_card_limit
                )}</span>`;
              }
              return '<span class="text-gray-500">₦ 0.00</span>';
            },
          },

          {
            name: "available_balance",
            th: "Available Balance",
            render: (card) => {
              if (card.personal_account) {
                return `<span class="text-blue-500">₦ ${this.$options.filters.currency(
                  card.personal_account?.available_balance
                )}</span>`;
              }
              return '<span class="text-gray-500">₦ 0.00</span>';
            },
          },
          {
            name: "status",
            th: "Status",
            render: (card) => {
              if (
                card.status == "active" &&
                card.personal_account?.status == "active"
              ) {
                return '<span class="badge badge-green-soft-outline">Active</span>';
              }

              if (card.personal_account?.pin_status) {
                return '<span class="badge badge-blue-soft-outline">Pin Requested</span>';
              }

              if (
                !card.pin_status &&
                card.user?.card_request?.status == "ready"
              ) {
                return '<span class="badge badge-orange-soft-outline">Pin Not Requested</span>';
              }

              return '<span class="badge badge-orange-soft-outline">Not Ready</span>';
            },
          },
        ],
      }),
    };
  },
  mounted() {
    this.$options.listenForClick.apply(this);
  },
  methods: {
    goToUser({ personal_account }) {
      const { user } = personal_account

      return this.$router.push({
        name: "staff-view",
        params: {
          staffId: user?.id,
        },
      });
    },
    async getCards() {
      this.cards.loading = true;
      await this.sendRequest("admin.cards.personal.all", {
        success: (response) => {
          this.cards.data = response.data.cards;
          if (this.request) {
            this.request = this.cards.data.find(
              (request) => request.id === this.request.id
            );
          }
        },
      });
      this.cards.loading = false;
    },
  },
};
</script>
